import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import AuthorBlock from "../../components/AuthorBlock"
import PageCover from "../../components/PageCover"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Gql from "../../typings/gql"
import chunk from "lodash/chunk"
import { AuthorBlockView } from "../../components/AuthorBlock";
import { AppContext } from "../../utils/appContext";
import { useIntl } from "react-intl"

interface Props {
  pageContext: AppContext;
}

const IndexPage: React.FC<Props> = ({ pageContext: { categories, mainMenu, mainSiteUrl } }) => {
  const { formatMessage: fm } = useIntl();
  const authorsResult = useStaticQuery<Gql.AuthorsQuery>(graphql`
    query Authors {
      allWordpressWpUsers {
        nodes {
          id
          name
          slug
          avatar_urls {
            wordpress_96
          }
          description
        }
      }
    }
  `)
  return (
    <Layout categories={categories} mainMenu={mainMenu} mainSiteUrl={mainSiteUrl}>
      <SEO />

      <PageCover title={fm({ id: "breadcrumb.authors" })} image={withPrefix("/images/cover-3.jpg")} />

      <div className="main bg-gray authors">
        {chunk(authorsResult.allWordpressWpUsers.nodes, 3).map((authorRow, i) =>
          <div key={i}>
            {authorRow.map(author =>
              <div className="columns medium-4" key={author.id}>
                <AuthorBlock view={AuthorBlockView.AuthorsPage} author={author} />
              </div>
            )}
          </div>
        )}
      </div>

    </Layout>
  )
}

export default IndexPage
